.layout-sidebar {
    position: fixed;
    width: 250px;
    height: 100%;
    // z-index: 999;
    overflow-y: auto;
    user-select: none;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;



    &.layout-sidebar-enter {
        transform: translateX(-100%);
    }

    &.layout-sidebar-enter-active {
        transform: translateX(0%);
        transition: transform $transitionDuration;
    }

    &.layout-sidebar-enter-done {
        transform: none;
    }

    &.layout-sidebar-exit {
        transform: translateX(0%);
    }

    &.layout-sidebar-exit-active {
        transform: translateX(-100%);
        transition: transform $transitionDuration;
    
    }

    .layout-logo {
        text-align: center;
        margin-top: 24px;
    }

    .menuitem-badge {
        display: inline-block;
        margin-left: 4px;
        font-size: 10px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        color: $menuitemBadgeColor;
        background-color: $menuitemBadgeBgColor;
        border-radius: 50%;
    }
}

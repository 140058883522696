@media screen and (max-width: 960px) {
  .dashboard .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
  .dashboard .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
    display: none !important;
    background-color: brown !important;
  }
  .dashboard .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
    border-bottom: 1px solid #dee2e6;
  }
  .dashboard .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }
  .dashboard .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4rem 1rem -0.4rem -0.4rem;
    font-weight: bold;
  }
  .dashboard .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
    margin-top: 0.5rem;
  }
}

.floatlabel-demo .p-field {
  margin-top: 2rem;
  margin-bottom: 0;
}

.input-demo .p-multiselect {
  min-width: 15rem;
}

.input-demo .multiselect-custom .country-item {
  display: flex;
  align-items: center;
}
.input-demo .multiselect-custom .country-item span.flag {
  width: 18px;
  height: 12px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.input-demo .multiselect-custom .country-item-value {
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.5rem;
  background-color: #2196f3;
  color: #ffffff;
}

.list-demo .product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.list-demo .product-description {
  margin: 0 0 1rem 0;
}

.list-demo .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.list-demo .product-category {
  font-weight: 600;
  vertical-align: middle;
}

.list-demo .product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}
.list-demo .product-list-item img {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
}
.list-demo .product-list-item .product-list-detail {
  flex: 1 1 0;
}
.list-demo .product-list-item .p-rating {
  margin: 0 0 0.5rem 0;
}
.list-demo .product-list-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  align-self: flex-end;
}
.list-demo .product-list-item .product-list-action {
  display: flex;
  flex-direction: column;
}
.list-demo .product-list-item .p-button {
  margin-bottom: 0.5rem;
}

.list-demo .product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.products-badge-status-Urgent {
  background: #c8e6c9;
  color: #256029;
}
.products-badge-status-Sufficient {
  background: #ffcdd2;
  color: #c63737;
}
.products-badge-status-Depleting-fast {
  background: #feedaf;
  color: #8a5340;
}

.list-demo .product-grid-item {
  margin: 0.5em;
  border: 1px solid #dee2e6;
}
.list-demo .product-grid-item .product-grid-item-top,
.list-demo .product-grid-item .product-grid-item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-demo .product-grid-item img {
  width: 75%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 2rem 0;
}
.list-demo .product-grid-item .product-grid-item-content {
  text-align: center;
}
.list-demo .product-grid-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .list-demo .product-list-item {
    flex-direction: column;
    align-items: center;
  }
  .list-demo .product-list-item img {
    width: 75%;
    margin: 2rem 0;
  }
  .list-demo .product-list-item .product-list-detail {
    text-align: center;
  }
  .list-demo .product-list-item .product-price {
    align-self: center;
  }
  .list-demo .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
  }
  .list-demo .product-list-item .product-list-action {
    margin-top: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.menu-demo .stepsdemo-content {
  padding: 1em 0;
}
.menu-demo .stepsdemo-content p {
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  margin: 0;
}
.menu-demo .stepsdemo-content i {
  vertical-align: middle;
  font-size: 1.5em;
  margin: 0;
}

.menu-demo .contextmenu-image {
  width: 100%;
}

.messages-demo .p-field > label {
  width: 125px;
}

.messages-demo .p-inputtext {
  margin-right: 0.5rem;
}

.misc-demo .badges .p-badge,
.misc-demo .badges .p-tag {
  margin-right: 0.5rem;
}

.misc-demo .badges .p-tag {
  line-height: 1;
}

.misc-demo .p-button {
  margin-right: 0.5rem;
}

.misc-demo .p-overlay-badge {
  margin-right: 2rem;
}

.overlay-demo p {
  line-height: 1.5;
  margin: 0;
}

.overlay-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel-demo .p-toolbar {
  flex-wrap: wrap;
  overflow: auto;
}

.panel-demo p {
  line-height: 1.5;
  margin: 0;
}

.panel-demo .p-card .p-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 1rem 1rem 0 1rem;
}

.panel-demo .p-card .p-card-body {
  padding-top: 0;
}

.table-demo {
  /* Responsive */
}
.table-demo .p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;
}
.table-demo .p-progressbar .p-progressbar-value {
  background-color: #607d8b;
}
.table-demo .p-datatable .p-column-filter {
  display: none;
}
.table-demo .table-header {
  display: flex;
  justify-content: space-between;
}
.table-demo .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}
.table-demo .p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}
.table-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
  text-align: left;
}
.table-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
  cursor: auto;
}
.table-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}
.table-demo .p-datatable-customers .p-datatable-tbody > tr > td > .p-column-title {
  display: none;
}
.table-demo .customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.table-demo .customer-badge.status-qualified {
  background: #c8e6c9;
  color: #256029;
}
.table-demo .customer-badge.status-unqualified {
  background: #ffcdd2;
  color: #c63737;
}
.table-demo .customer-badge.status-negotiation {
  background: #feedaf;
  color: #8a5340;
}
.table-demo .customer-badge.status-new {
  background: #b3e5fc;
  color: #23547b;
}
.table-demo .customer-badge.status-renewal {
  background: #eccfff;
  color: #694382;
}
.table-demo .customer-badge.status-proposal {
  background: #ffd8b2;
  color: #805b36;
}
.table-demo .p-progressbar-value.ui-widget-header {
  background: #607d8b;
}
@media (max-width: 640px) {
  .table-demo .p-progressbar {
    margin-top: 0.5rem;
  }
}
.table-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.table-demo .orders-subtable {
  padding: 1rem;
}
.table-demo .product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.table-demo .product-badge.status-instock {
  background: #c8e6c9;
  color: #256029;
}
.table-demo .product-badge.status-outofstock {
  background: #ffcdd2;
  color: #c63737;
}
.table-demo .product-badge.status-lowstock {
  background: #feedaf;
  color: #8a5340;
}
.table-demo .order-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.table-demo .order-badge.order-delivered {
  background: #c8e6c9;
  color: #256029;
}
.table-demo .order-badge.order-cancelled {
  background: #ffcdd2;
  color: #c63737;
}
.table-demo .order-badge.order-pending {
  background: #feedaf;
  color: #8a5340;
}
.table-demo .order-badge.order-returned {
  background: #eccfff;
  color: #694382;
}
@media screen and (max-width: 960px) {
  .table-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
  .table-demo .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .table-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }
  .table-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4rem 1rem -0.4rem -0.4rem;
    font-weight: bold;
  }
  .table-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
    margin-top: 0.5rem;
  }
  .table-demo .p-datatable.p-datatable-customers colgroup {
    display: none;
  }
  .table-demo .p-datatable .p-datatable-header .table-header {
    flex-direction: column !important;
  }
}

.text-demo .demo-container {
  border: 2px solid #dee2e6;
}

.spacing-demo .demo-container {
  border: 1px solid #dee2e6;
}

.icons-demo .icons-list {
  text-align: center;
  color: #6c757d;
}
.icons-demo .icons-list .p-md-2 {
  padding: 1em;
}

.icons-demo .icons-list i {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.flexgrid-demo .box {
  background-color: #ffffff;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.flexgrid-demo .box-stretched {
  height: 100%;
}

.flexgrid-demo .vertical-container {
  margin: 0;
  height: 200px;
  background: #dee2e6;
  border-radius: 4px;
}

.flexgrid-demo .nested-grid .p-col-4 {
  padding-bottom: 1rem;
}

.flexgrid-demo .dynamic-box-enter-active,
.flexgrid-demo .dynamic-box-leave-active {
  transition: all 0.5s;
}

.flexgrid-demo .dynamic-box-enter-from,
.flexgrid-demo .dynamic-box-leave-to {
  opacity: 0;
}

.flexgrid-demo .dynamic-box-enter-from,
.flexgrid-demo .dynamic-box-leave-to {
  transform: translateX(30px);
}

.flexgrid-demo p {
  margin: 0;
}

.flexbox-demo .p-d-flex > div,
.flexbox-demo .box {
  background-color: #ffffff;
  text-align: center;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.flexbox-demo .p-d-flex > div {
  width: 8rem;
}

.elevation-demo .box {
  min-height: 100px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  margin: 2rem;
  border-radius: 4px;
}

.elevation-demo .shadow-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.crud-demo {
  /* Responsive */
}
.crud-demo .table-header {
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row !important;
  }
}
.crud-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.crud-demo .p-toolbar {
  display: flex;
  flex-wrap: wrap;
}
.crud-demo .p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.crud-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.crud-demo .product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.crud-demo .product-badge.status-instock {
  background: #c8e6c9;
  color: #256029;
}
.crud-demo .product-badge.status-outofstock {
  background: #ffcdd2;
  color: #c63737;
}
.crud-demo .product-badge.status-lowstock {
  background: #feedaf;
  color: #8a5340;
}
.crud-demo .datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
@media screen and (max-width: 960px) {
  .crud-demo .p-datatable.datatable-responsive .p-datatable-thead > tr > th,
  .crud-demo .p-datatable.datatable-responsive .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--surface-d);
  }
  .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr > td {
    text-align: left;
    display: flex;
    align-items: center;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }
  .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4rem 1rem -0.4rem -0.4rem;
    font-weight: bold;
  }
  .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr > td .p-progressbar {
    margin-top: 0.5rem;
  }
  .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr > td .actions {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
}

.docs h1 {
  margin-top: 28px;
  margin-bottom: 14px;
}

.docs p {
  line-height: 1.5;
}

pre[class*="language-"] {
  padding: 0 !important;
  background: transparent;
  overflow: visible;
}
pre[class*="language-"]:after,
pre[class*="language-"]:before {
  box-shadow: none;
}
pre[class*="language-"] > code {
  box-shadow: none !important;
  border-left: 10px solid #0388e5;
}

.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.p-timeline-event-content,
.p-timeline-event-opposite {
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .customized-timeline .p-timeline-event:nth-child(even) {
    flex-direction: row !important;
  }
  .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
    text-align: left !important;
  }
  .customized-timeline .p-timeline-event-opposite {
    flex: 0;
  }
  .customized-timeline .p-card {
    margin-top: 1rem;
  }
}

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/
/* 
@font-face {
  font-family: Poppins-Regular;
  src: url('../fonts/poppins/Poppins-Regular.ttf'); 
}

@font-face {
  font-family: Poppins-Medium;
  src: url('../fonts/poppins/Poppins-Medium.ttf'); 
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../fonts/poppins/Poppins-Bold.ttf'); 
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('../fonts/poppins/Poppins-SemiBold.ttf'); 
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../fonts/montserrat/Montserrat-Bold.ttf'); 
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('../fonts/montserrat/Montserrat-SemiBold.ttf'); 
}

@font-face {
  font-family: Montserrat-Regular;
  src: url('../fonts/montserrat/Montserrat-Regular.ttf'); 
} */

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: "poppins";
}

/*---------------------------------------------*/
a {
  font-family: "poppins";
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #6675df;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #999999;
}
input:-moz-placeholder {
  color: #999999;
}
input::-moz-placeholder {
  color: #999999;
}
input:-ms-input-placeholder {
  color: #999999;
}

textarea::-webkit-input-placeholder {
  color: #999999;
}
textarea:-moz-placeholder {
  color: #999999;
}
textarea::-moz-placeholder {
  color: #999999;
}
textarea:-ms-input-placeholder {
  color: #999999;
}

label {
  display: block;
  margin: 0;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ utility ]*/

/*==================================================================
[ Text ]*/
.txt1 {
  font-family: "poppins";
  font-size: 16px;
  line-height: 1.4;
  color: #555555;
  margin-left: 20.5rem;
}

.txt2 {
  font-family: "poppins";
  font-size: 13px;
  line-height: 1.4;
  color: #999999;
}

/*==================================================================
[ Size ]*/
.size1 {
  width: 355px;
  max-width: 100%;
}

.size2 {
  width: calc(100% - 43px);
}

/*==================================================================
[ Background ]*/
.bg1 {
  background: #3b5998;
}
.bg2 {
  background: #1da1f2;
}
.bg3 {
  background: #cd201f;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
}

.wrap-login100 {
  width: 100%;
  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
}

/*==================================================================
[ login more ]*/
.login100-more {
  width: calc(100% - 560px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-image: url("/src/images/loginbg.png");
}

.login100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

/*==================================================================
[ Form ]*/

.login100-form {
  width: 560px;
  min-height: 100vh;
  display: block;
  background-color: #f7f7f7;
  padding: 173px 55px 55px 55px;
}

.login100-form-title {
  width: 100%;
  display: block;
  font-family: "Poppins";
  font-style: "SemiBold";
  font-size: 50px;

  color: darkgrey;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 50px;
}

/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 60px;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-bottom: 20px;
}

.label-input100 {
  font-family: "poppins";
  font-size: 18px;
  color: #999999;
  line-height: 1.2;

  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  padding-left: 24px;
  left: 0;
  top: 30px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-family: "poppins";
  font-size: 18px;
  color: #555555;
  line-height: 1.2;
  padding: 0 26px;
}

input.input100 {
  height: 100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

/*---------------------------------------------*/

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #6675df;
  border-radius: 10px;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.2);
  -moz-transform: scaleX(1.1) scaleY(1.2);
  -ms-transform: scaleX(1.1) scaleY(1.2);
  -o-transform: scaleX(1.1) scaleY(1.2);
  transform: scaleX(1.1) scaleY(1);
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.eff-focus-selection {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.input100:focus {
  height: 48px;
}

.input100:focus + .focus-input100 + .label-input100 {
  top: 14px;
  font-size: 13px;
}

.has-val {
  height: 48px !important;
}

.has-val + .focus-input100 + .label-input100 {
  top: 14px;
  font-size: 13px;
}

/*==================================================================
[ Restyle Checkbox ]*/

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #999999;
  line-height: 1.4;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "\f00c";
  font-family: "poppins";
  font-size: 13px;
  color: transparent;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #6675df;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
  color: #6675df;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  /* width: 100%; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: #6675df;

  font-family: "poppins";
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.nextbutton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  /* width: 100%; */
  height: 40px;
  /* border-radius: 10px; */
  /* background: #6675df; */

  font-family: "poppins";
  font-size: 12px;
  color: white;
  /* border: 1px solid #6675df; */
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.nextbutton1 {
  /* display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  /* width: 100%; */
  /* height: 40px; */
  /* border-radius: 10px; */
  background: #efeff3;
  border: none;

  font-family: "poppins";
  font-size: 12px;
  color: rgb(14, 13, 13);
  /* border: 1px solid #6675df; */
  /* line-height: 1.2; */
  text-transform: uppercase;
  letter-spacing: 1px;

  /* -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s; */
}
/* .nextbutton1 :hover{
background-color: white;
color: black;




} */
.previousbutton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  /* width: 100%; */
  height: 40px;
  /* border-radius: 10px; */
  background: white;

  font-family: "poppins";
  font-size: 12px;
  color: #6675df;
  /* border: 1px solid #6675df; */
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #6675df;
}
/* .forgot100-form-btn:hover {
  background: #6675df;
  color: white;
} */

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 992px) {
  .login100-form {
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .login100-more {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .login100-form {
    width: 100%;
  }

  .login100-more {
    display: none;
  }
}

@media (max-width: 576px) {
  .login100-form {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
  }
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  z-index: 100;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;

  font-family: Poppins-Regular;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: "poppins";
  display: block;
  position: absolute;
  z-index: 110;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/*==================================================================
[ Social ]*/
.login100-form-social-item {
  width: 36px;
  height: 36px;
  font-size: 18px;
  color: #fff;
  border-radius: 50%;
}

.login100-form-social-item:hover {
  background: #333333;
  color: #fff;
}

.gridcol {
  display: flex;
  justify-content: space-between;
  margin-bottom: 75%;
  margin-top: 5%;
  margin-left: 25px;
}
.gridcheck {
  display: flex;
  margin-top: 5px;
  margin-left: 25px;
}
.productss-badge.status-50 {
  /* font-weight: 800; */
  background-color: #ff9999 !important;
  /* text-decoration: line-through !important; */
}
.p-dropdow {
  background-color: #c7c2c2 !important;
}
.row-accessories {
  background: #f3f3f3 !important;
  opacity: 1;
}

.paddingThTd {
  padding: 0.5rem 1rem !important;
}

.bestModel{
  background: #73c228 !important;
}
.row-access {
  background-color: rgba(196, 24, 24, 0.15) !important;
}
div.layout-wrapper {
  /* padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
  min-height: 0vh;
}
/* .rowexpanded{
  background-color: red !important;
  
  
} */
.row-p-datatable .p-datatable-thead > tr > th {
  background-color: rgb(233, 168, 182) !important;
}
.rows-p-datatable .p-datatable-thead > tr > th {
  background-color: rgba(168, 202, 233, 0.836) !important;
}

.submenu-list {
  z-index: 9999;
}

.homeButtons {
  width: 230px;
  font-size: medium;
}

.layout-config {
	position: fixed;
	top: 50px;
	padding: 0;
	right: 0;
	display: block;
	width: 20em;
	z-index: 998;
	height: calc(100% - 50px);
	transform: translate3d(20em, 0px, 0px);
	transition: transform $transitionDuration;
	backface-visibility: hidden;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
	color: $textColor;
	background-color: #ffffff;

	&.layout-config-active {
		transform: translate3d(0px, 0px, 0px);

		.layout-config-content {
			.layout-config-button {
				i {
					transform: rotate(360deg);
				}
			}
		}
	}

	.layout-config-button {
		display: block;
		position: absolute;
		width: 52px;
		height: 52px;
		line-height: 52px;
		background-color: $menuitemBadgeBgColor;
		text-align: center;
		color: $menuitemBadgeColor;
		top: 230px;
		left: -52px;
		z-index: -1;
		overflow: hidden;
		cursor: pointer;
		transition: background-color $transitionDuration;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);

		i {
			font-size: 32px;
			line-height: inherit;
			cursor: pointer;
			transform: rotate(0deg);
			transition: color $transitionDuration, transform 1s;
		}
	}

	.layout-config-close {
		position: absolute;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		right: 20px;
		top: 10px;
		z-index: 1;
		background-color: #e54a51;
		transition: background-color $transitionDuration;
		border-radius: 50%;

		i {
			color: #ffffff;
			line-height: inherit;
			font-size: 12px;
		}

		&:hover {
			background-color: #ea6e73;
		}
	}

	.layout-config-content {
		position: relative;
		overflow: auto;
		height: 100%;
		padding: 1em;
		padding-bottom: 100px;

		.layout-themes {
			display: flex;
			flex-wrap: wrap;

			> div {
				padding: .25rem;
			}


			a {
				width: 2rem;
				height: 2rem;
				border-radius: 50%;
				display: block;
				position: relative;
				align-items: center;
				justify-content: center;
				transition: transform $transitionDuration;
				box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);

				i {
					font-size: 1rem;
					position: absolute;
					color: $menuitemBadgeColor;
					top: 50%;
					left: 50%;
					margin-left: -.5rem;
					margin-top: -.5rem;
				}

				&:hover {
					transform: scale(1.1);
				}
			}
		}
	}

	.p-col {
		text-align: center;
	}

	p {
		line-height: 1.5;
		margin-top: 0;
		color: $textSecondaryColor;
	}
}

.blocked-scroll-config{
	overflow: hidden;
}

@media screen and (max-width: 1024px) {
	.layout-config {
		top: 50px;
		transform: translate3d(100%, 0px, 0px);

		&.layout-config-active {
			transform: translate3d(0px, 0px, 0px);
		}

		.layout-config-close {
			right: 10px;
		}
	}
}

/* Typography */
.card {
    background-color: #ffffff;
    padding: 1em;
    margin-bottom: 16px;
    // border-radius: 15px;
    box-shadow: 0rem 0rem 0.5rem 0.5rem rgba(133, 130, 130, 0.08);


    &.card-w-title {
        padding-bottom: 2em;
    }
}
